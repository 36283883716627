<template>
  <div class='body'>
    <Nav></Nav>
    <div class="banner">
        <swiper ref="bannerSwiper" :options="bannerSwiperOptions" v-if="pageData.bannerList && pageData.bannerList.length">
          <swiper-slide v-for="img in pageData.bannerList" :key="img.id">
            <base-img class="banner-img" :src="img.url"></base-img>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    <div class='page-body page-content flex-j-b'>
      <div class='case-box'>
        
          <div v-if='pageData.subCompany[Number(tab)] && type==="progress"' class='card-box'>
            <div class='tab-title'>在建工程</div>
            <div class="flex-row-wrap" v-viewer v-if='pageData.subCompany[Number(tab)] && pageData.subCompany[Number(tab)].progress.length'>
              <div v-for="(item,index) in pageData.subCompany[Number(tab)].progress" :key="item.id + index" class="case-img">
                <base-img :src="item.url"></base-img>
                <a-popover>
                  <template slot="content">
                    <p>{{item.title}}</p>
                  </template>
                  <div class='case-img-name'>{{item.title}}</div>
                </a-popover>
              </div>
            </div>
            <div v-else>
              <a-empty description='暂无图片'></a-empty>
            </div>
          </div>
          <div v-if='pageData.subCompany[Number(tab)] && type==="done"' class='card-box'>
            <div class='tab-title'>完工工程</div>
            <div class="flex-row-wrap" v-viewer v-if='pageData.subCompany[Number(tab)] && pageData.subCompany[Number(tab)].done.length'>
              <div v-for="(item,index) in pageData.subCompany[Number(tab)].done" :key="item.id + index" class="case-img">
                <base-img :src="item.url"></base-img>
                <a-popover>
                  <template slot="content">
                    <p>{{item.title}}</p>
                  </template>
                  <div class='case-img-name'>{{item.title}}</div>
                </a-popover>
              </div>
            </div>
            <div v-else>
              <a-empty description='暂无图片'></a-empty>
            </div>
          </div>
        </div>
    </div>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/pageComponents/Nav'
import Footer from '@/components/pageComponents/Footer'
import { design } from '@/api'
export default {
  name: 'case',
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      tab: this.$route.params.tab,
      type: this.$route.params.type,
      pageData: {
        case: [],
      },
      bannerSwiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        effect: 'fade',
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        loop: true,
      }
    }
  },
  async mounted() {
    await this.getDesignData()
  },
  methods: {
    async getDesignData() {
      const { data, msg, code } = await design.getDesign({ type: 'subCompany' })
      if (code === '00000') {
        if (data) {
          this.designStorageId = data.designStorageId
          this.pageData = JSON.parse(data.designData)
        }
        console.log(this.pageData.subCompany[Number(this.tab)][this.type])
        if (this.pageData.subCompany.length === 0) {
          const arr = this.leftMenuData.map((item) => {
            return {
              ...item,
              progress: [],
              done: [],
            }
          })
          this.$set(this.pageData, 'subCompany', arr)
        }
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.body {
  background: #f1f1f1;
}
.page-content {
  padding: 24px 0;
}
.case-box {
  min-height: 300px;
  width: 100%;
}
.tab-title {
  font-size: 32px;
  color: #38b352;
  margin-bottom: 48px;
  text-align: center;
}
.tab-desc {
  font-size: 16px;
  color: #2b2b2b;
  line-height: 28px;
  margin-bottom: 64px;
  text-align: left;
  text-indent: 2em;
  // padding: 0 100px;
}
.flex-row-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.flex-row-wrap .case-img {
  width: calc((100% - 64px) / 5);
  object-fit: cover;
  display: block;
  margin-right: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  position: relative;
}
.flex-row-wrap .case-img:nth-of-type(5n) {
  margin-right: 0;
}
.flex-row-wrap .case-img:hover ::v-deep img {
  transform: scale(1.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}
.flex-row-wrap .case-img ::v-deep .base-img {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.flex-row-wrap .case-img ::v-deep img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 1s;
}
.case-img-name {
  font-size: 16px;
  line-height: 32px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.card-box {
  margin-bottom: 24px;
  box-shadow: 0 4px 8px #ddd;
  background: #fff;
  padding: 32px 24px 24px;
  border-radius: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}

.more {
  font-size: 16px;
  color: #38b352;
  border-radius: 22px;
  text-align: center;
  margin: 32px auto 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .icon {
    margin-left: 4px;
  }
}
</style>